import React from 'react';
import {getOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import * as AppActions from '../../AppActions';
import Config from '../../../data.json';

export function handleRebuildHistory({path, pageContext}) {
  if (path.indexOf('/admin/rebuild-history') > -1) {
    pageContext.resource = {
      ...pageContext.resource,
      renderCreateButton: ({clientCallbacks}) => {
        return (
          <Wrapper>
            <Popconfirm
              title="前台更新屬於計費項目，確認更新嗎？"
              okText="確認"
              cancelText="取消"
              onConfirm={async () => {
                await githubRebuild();
                clientCallbacks.refresh();
              }}>
              <Button
                ghost
                type="primary"
                disabled={!Config.allowGithubRebuild}>
                更新
              </Button>
            </Popconfirm>
          </Wrapper>
        );
      },
    };
  }
}

const UserOutlet = getOutlet('user');

async function githubRebuild() {
  AppActions.setLoading(true);

  try {
    if (Config.is_frontend_mkt_deploy) {
      // v2 deploy
      await fetch(
        `${Config.apiHost}/misc/github/rebuild?access_token=${
          UserOutlet.getValue().token
        }&branch=main&stage=prod&client_id=${
          Config.clientId
        }&event_type=mkt_deploy`,
      );
    } else {
      // legacy deploy, must branch out
      await fetch(
        `${Config.apiHost}/misc/github/rebuild?access_token=${
          UserOutlet.getValue().token
        }&branch=${Config.branch}&stage=${Config.stage}&client_id=${
          Config.clientId
        }&event_type=webhook`,
      );
    }

    message.success('更新成功，系統將於 10 分鐘後部署完成。');
  } catch (err) {
    console.log('err', err);
    message.error('更新失敗。');
  }

  AppActions.setLoading(false);
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;
