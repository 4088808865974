import React from 'react';
import * as Ant from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';
import * as CatUtil from '../../Utils/CatUtil';

export function handleExtra({path, pageContext}) {
  /* 
    if you want to custom behavier for admin resource  
    EXAMPLE: 
    if (path.indexOf('xxx') > -1) {
      pageContext.resource.xxx = xxx;
    }
  */
}
